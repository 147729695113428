<template>
  <div>
    <breadCrumb title="Seller Support Policy" :menuList="menuList"></breadCrumb>

    <section class="mb-4">
      <div class="container">
        <div class="p-4 bg-white rounded shadow-sm overflow-hidden mw-100 text-left">
          <template v-if="content.length > 0">
            <div v-html="content"></div>
          </template>
          <template v-else>

          <h1>{{ appName }} Seller Support Policy</h1>
          <p>
            {{ appName }} is committed to providing comprehensive support to sellers, helping them succeed on the platform. Our seller support policy includes the following:
          </p>
          <ul>
            <li>
              <b>Onboarding support</b>: We provide a one-stop onboarding service to help sellers quickly complete the onboarding process.
            </li>
            <li>
              <b>Marketing support</b>: We provide a variety of marketing tools and resources to help sellers increase brand awareness and sales.
            </li>
            <li>
              <b>Operational support</b>: We provide professional operational guidance to help sellers improve store operational efficiency.
            </li>
            <li>
              <b>Service support</b>: We provide comprehensive after-sales service to protect the rights of sellers and consumers.
            </li>
          </ul>
          <p>
            <b>Specific support measures</b>
          </p>
          <ul>
            <li>
              <b>Onboarding support</b>
              <ul>
                <li>
                  Provide a seller onboarding manual and video tutorial to help sellers understand the onboarding process and precautions.
                </li>
                <li>
                  Provide a seller onboarding specialist to provide one-on-one guidance to sellers.
                </li>
                <li>
                  Offer a free seller trial period for sellers to experience platform features before official onboarding.
                </li>
              </ul>
            </li>
            <li>
              <b>Marketing support</b>
              <ul>
                <li>
                  Provide a variety of marketing tools, including live streaming, short videos, and advertising, to help sellers increase brand awareness and sales.
                </li>
                <li>
                  Provide marketing training and events to help sellers improve their marketing skills.
                </li>
                <li>
                  Provide marketing data analysis to help sellers optimize their marketing strategies.
                </li>
              </ul>
            </li>
            <li>
              <b>Operational support</b>
              <ul>
                <li>
                  Provide operational guidance to help sellers improve store operational efficiency.
                </li>
                <li>
                  Provide operational tools and resources to help sellers manage stores and products.
                </li>
                <li>
                  Provide operational training to help sellers improve their operational skills.
                </li>
              </ul>
            </li>
            <li>
              <b>Service support</b>
              <ul>
                <li>
                  Provide comprehensive after-sales service to protect the rights of sellers and consumers.
                </li>
                <li>
                  Provide after-sales training to help sellers improve their after-sales service level.
                </li>
                <li>
                  Provide after-sales data analysis to help sellers optimize their after-sales service process.
                </li>
              </ul>
            </li>
          </ul>
          <p>
            <b>Conclusion</b>
            <p>
              {{ appName }} will continue to strive to provide sellers with better support, helping them succeed on the platform.
            </p>
          </p>
          </template>
        </div>
      </div>
    </section>

  </div>
</template>
<script>
import breadCrumb from '@/components/breadCrumb'
import {getArticleLists} from "@/api";
export default {
  components: {
    breadCrumb
  },
  data() {
    return {
      menuList: [
        {
          title: '支持政策',
          path: ''
        },
      ],
      content: ''
    }
  },
  computed: {
    appName() {
      return this.$store.state.appName;
    }
  },
  mounted() {
    this.GetArticleData()
  },
  methods: {
    GetArticleData() {
      let f = new FormData()
      f.append('Type', '3')
      getArticleLists(f).then(res => {
        if(res.data.Items.length > 0){
          this.content = res.data.Items[0].Content
        }
      })
    },
    toPath(path) {
      if (path) {
        this.$router.push({
          name: path
        })
      }
    },
  }
}
</script>